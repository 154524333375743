document.addEventListener('DOMContentLoaded', () => {

    /**
     * Close all collapsible groups by default
     */
    /* if($('.fi-resource-shows.fi-resource-list-records-page').length) {
        // This is a f**ing hack to wait until filament is loaded.
        // For sure there is a better way to do this.
        setTimeout(() => {
            $('.fi-ta-group-header').trigger('click');
        }, 1000);
    } */
})
